import React, { useEffect, useState } from "react";

import {
  FormLabel,
  FormHelperText,
  Flex,
  FormControl,
  FormErrorMessage,
  SimpleGrid,
  Stack,
  AspectRatio,
} from "@chakra-ui/react";
import { formatMoney, getTiktokVideoId } from "../../../utils";
import { Input } from "../../../components/Input";
import { Field } from "formik";
import { useLingui } from "@lingui/react";
import { getFormError } from "../../../i18n";
import { Trans, msg } from "@lingui/macro";
import TikTokPlayer from "../../../components/TiktokPlayer";
const TiktokSelector = ({ overlay, values, setFieldError, currency }) => {
  const { mediaType, tiktok = "", tiktokStart } = values;
  const {
    min_media: minimumMedia,
    mediashare_rate: mediashareRate,
    mediashare_max_duration: mediashareMaxDuration,
  } = overlay;
  const [videoId, setVideoId] = useState()

  const getPlayDuration = (
    mediashareRate,
    mediashareMaxDuration,
    amount,
  ) => {
    return ~~Math.min(amount / mediashareRate, mediashareMaxDuration);
  };
  useEffect(async () => {
  const {id} = await getTiktokVideoId(tiktok);
  setVideoId(id)
  }, [tiktok]) 
  const { _ } = useLingui();
  const enabled = Number(values.amount) >= minimumMedia;
  if (!enabled) {
    return (
      <FormControl width="100%" mb={3}>
        <FormHelperText>
          <Trans>
            Dukungan minimum untuk menyertakan Tiktok adalah{" "}
            {formatMoney(minimumMedia, { currency })}
          </Trans>
        </FormHelperText>
      </FormControl>
    );
  }
  return (
    <>
      {values.tiktok && mediaType === "tiktok" ? (
        <AspectRatio w="100%" ratio={16 / 9}>
      <TikTokPlayer
        autoplay={true}
        videoId={videoId}
        onError={() => {
          setFieldError("tiktok", msg`Cek ulang URL youtube diatas`);
        }}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
          />
        </AspectRatio>
      ) : null}
      <Stack columns="2" spacing="8">
        <FormControl width="100%">
          <FormHelperText>
            <Trans>
              {formatMoney(mediashareRate, { currency })} / detik <br /> Durasi
              maksimum video {mediashareMaxDuration} detik <br />
              Video akan diputar mulai detik 0 hingga{" "}
            </Trans>
            {getPlayDuration(
              mediashareRate,
              mediashareMaxDuration,
              values.amount,
              tiktokStart
            )}
          </FormHelperText>
          <FormHelperText></FormHelperText>
        </FormControl>
      </Stack>

      <SimpleGrid columns="2" spacing="8">
        <Field name="tiktok">
          {({ field, form }) => {
            return (
              <FormControl
                mb={3}
                isInvalid={form.errors.tiktok && form.touched.tiktok}
                flexGrow={1}
                isDisabled={!enabled}
              >
                <Flex direction="column" justify="flex-end">
                  <FormLabel htmlFor="media">Tiktok Url:</FormLabel>
                  <Input
                    {...field}
                    type="text"
                    id="tiktok"
                  />

                  <FormErrorMessage>
                    {getFormError(form.errors.tiktok, _)}
                  </FormErrorMessage>
                </Flex>
              </FormControl>
            );
          }}
        </Field>
      </SimpleGrid>
    </>
  );
};

export { TiktokSelector};

import React from "react";

import {
  FormLabel,
  FormHelperText,
  Flex,
  FormControl,
  FormErrorMessage,
  SimpleGrid,
  Stack,
  AspectRatio,
} from "@chakra-ui/react";
import { formatMoney } from "../../../utils";
import { Input } from "../../../components/Input";
import { Field } from "formik";
import YouTube from "react-youtube";
import getVideoId from "get-video-id";
import { useLingui } from "@lingui/react";
import { getFormError } from "../../../i18n";
import { Trans, msg } from "@lingui/macro";

const YTSelector = ({ overlay, values, setFieldError, currency }) => {
  const { mediaType, yt, ytStart } = values;
  const {
    min_media: minimumMedia,
    mediashare_rate: mediashareRate,
    mediashare_max_duration: mediashareMaxDuration,
  } = overlay;

  const getPlayDuration = (
    mediashareRate,
    mediashareMaxDuration,
    amount,
    ytStart
  ) => {
    return ~~Math.min(amount / mediashareRate, mediashareMaxDuration) + ytStart;
  };
  const { id: videoId } = getVideoId(yt);
  const { _ } = useLingui();
  const enabled = Number(values.amount) >= minimumMedia;
  if (!enabled) {
    return (
      <FormControl width="100%" mb={3}>
        <FormHelperText>
          <Trans>
            Dukungan minimum untuk menyertakan YT adalah{" "}
            {formatMoney(minimumMedia, { currency })}
          </Trans>
        </FormHelperText>
      </FormControl>
    );
  }
  return (
    <>
      {values.yt && mediaType === "yt" ? (
        <Flex w="100%" h="100%" justify="center" ratio="1" mb="3">
        <AspectRatio w="100%" ratio={16 / 9}>
            <YouTube
              opts={{
                playerVars: {
                  start: values.ytStart,
                  controls: 1,
                },
              }}
              videoId={videoId}
              onError={() => {
                setFieldError("yt", msg`Cek ulang URL youtube diatas`);
              }}
            ></YouTube>
</AspectRatio>
        </Flex>
      ) : null}
      <Stack columns="2" spacing="8">
        <FormControl width="100%">
          <FormHelperText>
            <Trans>
              {formatMoney(mediashareRate, { currency })} / detik <br /> Durasi
              maksimum video {mediashareMaxDuration} detik <br />
              Video akan diputar mulai detik {values.ytStart} hingga{" "}
            </Trans>
            {getPlayDuration(
              mediashareRate,
              mediashareMaxDuration,
              values.amount,
              ytStart
            )}
          </FormHelperText>
          <FormHelperText></FormHelperText>
        </FormControl>
      </Stack>

      <SimpleGrid columns="2" spacing="8">
        <Field name="yt">
          {({ field, form }) => {
            return (
              <FormControl
                mb={3}
                isInvalid={form.errors.yt && form.touched.yt}
                flexGrow={1}
                isDisabled={!enabled}
              >
                <Flex direction="column" justify="flex-end">
                  <FormLabel htmlFor="media">Youtube Url:</FormLabel>
                  <Input
                    {...field}
                    type="text"
                    id="yt"
                    placeholder="https://www.youtube.com/watch?v=4arNJoJ98Og"
                  />

                  <FormErrorMessage>
                    {getFormError(form.errors.yt, _)}
                  </FormErrorMessage>
                </Flex>
              </FormControl>
            );
          }}
        </Field>
        <Field name="ytStart">
          {({ field, form }) => {
            return (
              <FormControl
                flexGrow={1}
                mb={3}
                isDisabled={!enabled}
                isInvalid={form.errors.ytStart && form.touched.ytStart}
              >
                <Flex direction="column" justify="flex-end">
                  <FormLabel htmlFor="media">
                    <Trans>Mulai detik:</Trans>
                  </FormLabel>

                  <Input
                    {...field}
                    color={!enabled ? null : "gray"}
                    type="number"
                    id="ytStart"
                    placeholder="0"
                  />

                  <FormErrorMessage>
                    {getFormError(form.errors.ytStart, _)}
                  </FormErrorMessage>
                </Flex>
              </FormControl>
            );
          }}
        </Field>
      </SimpleGrid>
    </>
  );
};

export { YTSelector };

import React from "react";
import { Flex, HStack } from "@chakra-ui/react";
import { CapyHappy as UserImage } from "../assets";
import Image from "next/image";

const Badge = ({ children, ...props }) => {
  return (
    <Flex
      border="default"
      p={3}
      borderRadius="md"
      {...props}
      bgColor="saweria-yellow.light"
    >
      {children}
    </Flex>
  );
};

const Username = ({
  username,
  badgeImage = UserImage,
  fontFamily,
  ...props
}) => {
  return (
    <HStack {...props}>
      <Flex fontFamily={fontFamily}>{username}</Flex>
      <Flex w={"2em"}>
        <Image src={badgeImage}></Image>
      </Flex>
    </HStack>
  );
};

const UsernameBadge = ({ username, badgeImage, ...props }) => {
  return (
    <Badge {...props}>
      <HStack>
        <Username username={username} badgeImage={badgeImage} />
      </HStack>
    </Badge>
  );
};

export { Badge, UsernameBadge, Username };

import React from "react";
import { Flex, FormControl, FormLabel } from "@chakra-ui/react";

import Button from "../../components/Button";

const VoteSelector = ({ tags, title, selectedVote, chooseVote }) => {
  const color = ["green", "blue", "pink", "purple", "yellow"];
  const pilihan = tags.map((t, i) => (
    <Button
      key={`pilihan-${t}-${i}`}
      flexGrow="1"
      flexBasis={"20%"}
      textOverflow="ellipsis"
      overflow="hidden"
      disabled={selectedVote == t}
      onClick={() => chooseVote(t)}
      mr={3}
      label={t}
      backgroundColor={`saweria-${color[i]}.normal`}
    />
  ));
  return (
    <FormControl isRequired mb={3}>
      <FormLabel htmlFor="message">{`Vote: ${title}`}</FormLabel>
      <Flex overflowX="scroll">{pilihan}</Flex>
    </FormControl>
  );
};

export { VoteSelector };

import React, { useRef, useEffect, useState } from 'react';

const TikTokPlayer = ({ videoId, width, height, onVideoEnd, autoplay, setMediaTime }) => {
  const iframeRef = useRef(null);
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === 'onMute') {
        setIsPlayerReady(true);
      }
      if (event.data.type === 'onStateChange' && event.data.value === 0 && onVideoEnd) {
        onVideoEnd()
      }
      if (event.data.type === 'onCurrentTime'  && setMediaTime) {
        setMediaTime(event.data.value.currentTime)
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (isPlayerReady) {    
      unMute()
    }
  }, [isPlayerReady])

  const unMute= () => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage({
        type: "unMute",
        value: true,
        "x-tiktok-player": true
      }, '*');
    }
  };

  return (
      <iframe
        ref={iframeRef}
        height={height}
        width={width}
        src={`https://www.tiktok.com/player/v1/${videoId}?&music_info=0&description=0&autoplay=${autoplay?1:0}&control=0&progress_bar=0&timestamp=0&rel=0&native_context_menu=0`}
        allow="autoplay && fullscreen"
        title="test"
      />
  );
};

export default TikTokPlayer;
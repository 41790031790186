import {
  Flex,
  IconButton,
  useBreakpointValue,
  WrapItem,
} from "@chakra-ui/react";

import { Large, P } from "../components/Type";

import GopayIcon from "../assets/gopay.png";
import OvoIcon from "../assets/ovo.png";
import DanaIcon from "../assets/dana.png";
import LinkAjaIcon from "../assets/linkaja.png";
import QrisIcon from "../assets/qris.png";
import ShopeePayIcon from "../assets/shopeepay.png";
import PayMayaIcon from "../assets/maya.png";
import GCashIcon from "../assets/gcash.png";
import GrabPayIcon from "../assets/grabpay.png";
import Image from "next/image";
import { usePaymentMethodSelector } from "../contexts/paymentMethodSelector";
import { Trans } from "@lingui/macro";
import { VENDOR_CUT } from "../utils";
import { useUserInfo } from "../hooks/useUserInfo";

const ICON_MAP = {
  // IDR
  gopay: GopayIcon,
  ovo: OvoIcon,
  dana: DanaIcon,
  linkaja: LinkAjaIcon,
  qris: QrisIcon,
  // PHP
  shopeepay: ShopeePayIcon,
  gcash: GCashIcon,
  paymaya: PayMayaIcon,
  grabpay: GrabPayIcon,
};

const PaymentButton = ({
  enabled,
  type,
  pressed,
  color = "green",
  backgroundColor = "saweria-green.normal",
  disabled = false,
  onClick,
  "data-testid": dataTestId,
  props,
}) => {
  const isMobile = useBreakpointValue([true, false]);
  if (!enabled) {
    return null;
  }

  const { selectedPaymentMethod, transactionFeePolicy} = usePaymentMethodSelector();
  return (
    <WrapItem flexGrow={1} >
      <IconButton
        _hover={{}}
        _active={{}}
        _disabled={{
          cursor: "not-allowed",
        }}
        w="100%"
        m={0}
        h="80px"
        filter={disabled ? "grayscale(75%)" : ""}
        transform={pressed ? "translate(0.1rem, 0.1rem)" : ""}
        transition="box-shadow .1s , transform .2s "
        disabled={disabled}
        onClick={onClick}
        color={color}
        backgroundColor={backgroundColor}
        border="1px solid black"
        borderRadius="md"
        {...props}
        boxShadow={
          disabled || pressed || selectedPaymentMethod == type
            ? "pressed"
            : "buttonRaised"
        }
        data-testid={dataTestId}
      >
        <Flex flexDirection="column" justifyContent="space-between" h="100%">
          <Flex justifyContent="flex-end" >
            <Image
              src={ICON_MAP[type]}
              layout="fill"
              objectFit="contain"
              placeholder="blur"
              style={{ height: "1px", width: "auto" }}
              height={0}
            />
          </Flex>
          {transactionFeePolicy == 'TIPPER' &&
            <Large pt={10}  marginBottom={0} paddingBottom={1} fontSize={["xs"]}>
              {VENDOR_CUT[type]}
            </Large>
          }
        </Flex>
      </IconButton>
    </WrapItem>
  );
};

export { PaymentButton };

import React, { useEffect, useState } from "react";
import {
  FormLabel,
  FormHelperText,
  Flex,
  FormControl,
  FormErrorMessage,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { formatMoney, getInstagramVideoId } from "../../../utils";
import { Input } from "../../../components/Input";
import { Field } from "formik";
import { useLingui } from "@lingui/react";
import { getFormError } from "../../../i18n";
import { Trans, msg } from "@lingui/macro";
import { InstagramEmbed } from 'react-social-media-embed';

const ReelsSelector = ({ overlay, values, setFieldError, currency }) => {
  const { mediaType, reels = "", igReelsStart } = values;
  const {
    min_media: minimumMedia,
    mediashare_rate: mediashareRate,
    mediashare_max_duration: mediashareMaxDuration,
  } = overlay;

  const videoId = getInstagramVideoId(reels);

  const getPlayDuration = (
    mediashareRate,
    mediashareMaxDuration,
    amount,
  ) => {
    return ~~Math.min(amount / mediashareRate, mediashareMaxDuration);
  };

  const { _ } = useLingui();
  const enabled = Number(values.amount) >= minimumMedia;
  if (!enabled) {
    return (
      <FormControl width="100%" mb={3}>
        <FormHelperText>
          <Trans>
            Dukungan minimum untuk menyertakan Instagram Reels adalah{" "}
            {formatMoney(minimumMedia, { currency })}
          </Trans>
        </FormHelperText>
      </FormControl>
    );
  }

  return (
    <>
      {values.reels && mediaType === "reels" && videoId ?  <Flex justifyContent="center" width="100%">
        <InstagramEmbed height={528} url={`https://www.instagram.com/p/${videoId}`} width={328}  captioned={false} />
      </Flex>
 : null}
      <Stack columns="2" spacing="8">
        <FormControl width="100%">
          <FormHelperText>
            <Trans>
              {formatMoney(mediashareRate, { currency })} / detik <br /> Durasi
              maksimum video {mediashareMaxDuration} detik <br />
              Video akan diputar mulai detik 0 hingga{" "}
            </Trans>
            {getPlayDuration(
              mediashareRate,
              mediashareMaxDuration,
              values.amount,
              igReelsStart
            )}
          </FormHelperText>
          <FormHelperText></FormHelperText>
        </FormControl>
      </Stack>

      <SimpleGrid columns="2" spacing="8">
        <Field name="reels">
          {({ field, form }) => {
            return (
              <FormControl
                mb={3}
                isInvalid={form.errors.reels && form.touched.reels}
                flexGrow={1}
                isDisabled={!enabled}
              >
                <Flex direction="column" justify="flex-end">
                  <FormLabel htmlFor="media">Instagram Reels Url:</FormLabel>
                  <Input
                    {...field}
                    type="text"
                    id="reels"
                  />

                  <FormErrorMessage>
                    {getFormError(form.errors.reels, _)}
                  </FormErrorMessage>
                </Flex>
              </FormControl>
            );
          }}
        </Field>
      </SimpleGrid>
    </>
  );
};

export { ReelsSelector };
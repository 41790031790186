import {
  HStack,
  Box,
  Button as B,
  SimpleGrid,
  useBreakpointValue,
  WrapItem,
  Wrap
} from "@chakra-ui/react";
import { PaymentButton } from "../components/PaymentButton";

const IDRPaymentMethods = ({
  enabledPayments: {
    isQRISEnabled,
    isGopayEnabled,
    isDanaEnabled,
    isLinkAjaEnabled,
    isOvoEnabled,
  },
  allowPayment,
  donate,
  isSubmitting,
}) => {
  const isDesktop = useBreakpointValue([false, false, true]);
  return (
    <>
      <Wrap columns={5} spacing={"0.5em"}>
          <PaymentButton
            backgroundColor={"saweria-yellow.normal"}
            disabled={!allowPayment}
            enabled={isQRISEnabled}
            data-testid={"qris-button"}
            loading={isSubmitting}
            type="qris"
            onClick={donate("qris")}
          />
 
        <PaymentButton
          disabled={!allowPayment}
          enabled={isGopayEnabled}
          data-testid={"gopay-button"}
          loading={isSubmitting}
          type="gopay"
          onClick={donate("gopay")}
        />
        <PaymentButton
          backgroundColor={"#4d3394"}
          disabled={!allowPayment}
          enabled={isOvoEnabled}
          data-testid={"ovo-button"}
          loading={isSubmitting}
          type="ovo"
          onClick={donate("ovo")}
        />

        <PaymentButton
          disabled={!allowPayment}
          backgroundColor={"#0c87e1"}
          enabled={isDanaEnabled}
          data-testid={"dana-button"}
          loading={isSubmitting}
          type="dana"
          onClick={donate("dana")}
        />
        <PaymentButton
          backgroundColor={"#e12428"}
          disabled={!allowPayment}
          enabled={isLinkAjaEnabled}
          data-testid={"linkaja-button"}
          loading={isSubmitting}
          type="linkaja"
          onClick={donate("linkaja")}
        />
      </Wrap>
    </>
  );
};

const PHPPaymentMethods = ({
  enabledPayments: {
    isGrabPayEnabled = true,
    isGcashEnabled = true,
    isPaymayaEnabled = true,
    isShopeePayEnabled = true,
  },
  allowPayment,
  donate,
  isSubmitting,
}) => {
  return (
    <SimpleGrid columns={4} spacing={["0.5em", "2em"]}>
      <PaymentButton
        disabled={!allowPayment}
        enabled={isShopeePayEnabled}
        data-testid={"shopee-button"}
        loading={isSubmitting}
        type="shopeepay"
        backgroundColor="saweria-pink.normal"
        onClick={donate("shopeepay")}
      />
      <PaymentButton
        disabled={!allowPayment}
        enabled={isGrabPayEnabled}
        data-testid={"grabpay-button"}
        loading={isSubmitting}
        type="grabpay"
        backgroundColor="saweria-green.normal"
        onClick={donate("grabpay")}
      />
      <PaymentButton
        disabled={!allowPayment}
        enabled={isGcashEnabled}
        data-testid={"gcash-button"}
        loading={isSubmitting}
        type="gcash"
        backgroundColor="saweria-green.normal"
        onClick={donate("gcash")}
      />
      <PaymentButton
        disabled={!allowPayment}
        enabled={isPaymayaEnabled}
        data-testid={"paymaya-button"}
        loading={isSubmitting}
        type="paymaya"
        backgroundColor="saweria-pink.normal"
        onClick={donate("paymaya")}
      />
    </SimpleGrid>
  );
};

const PaymentMethods = ({
  allowPayment,
  isSubmitting,
  donate,
  currency = "IDR",
  enabledPayments,
}) => {
  const propsToPass = {
    allowPayment,
    donate,
    isSubmitting,
    enabledPayments,
  };
  switch (currency) {
    case "IDR":
      return <IDRPaymentMethods {...propsToPass} />;
    case "PHP":
      return <PHPPaymentMethods {...propsToPass} />;
    default:
      return null;
  }
};

export { PaymentMethods };

import React from "react";


const PaymentMethodSelectorContext= React.createContext({});

const PaymentMethodSelectorProvider = ({ children }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState("");
  const [transactionFeePolicy, setTransactionFeePolicy] = React.useState("");

  return (
    <PaymentMethodSelectorContext.Provider
      value={{
        selectedPaymentMethod,setSelectedPaymentMethod , transactionFeePolicy, setTransactionFeePolicy
      }}
    >
      {children}
    </PaymentMethodSelectorContext.Provider>
  );
};

const usePaymentMethodSelector = () => {
  const val = React.useContext(PaymentMethodSelectorContext);
  return val;
};

export { PaymentMethodSelectorProvider, usePaymentMethodSelector};

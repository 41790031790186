import React, { useState, useRef } from "react";
import {
  FormControl,
  FormHelperText,
  HStack,
  Stack,
  Text,
  Button,
  useToast,
  Box,
} from "@chakra-ui/react";

import { formatMoney } from "../../../utils";
import { Trans } from "@lingui/macro";
import { FaMicrophone } from "react-icons/fa6";
import { FaMicrophoneSlash } from "react-icons/fa6";
import { blobToBase64 } from "../../../utils";

const VoiceNoteForm = ({ onRecordingComplete, mediashareRate, mediashareMaxDuration, donationAmount }) => {
  const toast = useToast();
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);

  // Add constants for limits
  const MAX_DURATION_SECONDS = 60; 
  const MAX_SIZE_BYTES = 5 * 1024 * 1024; // 5 MB

  // Update maxDuration calculation to include the 1-minute limit
  const maxDuration = Math.min(
    Math.floor(donationAmount / mediashareRate),
    mediashareMaxDuration,
    MAX_DURATION_SECONDS
  );

  const handleRecordingComplete = async (blob) => {
    try {
      // Check file size before proceeding
      if (blob.size > MAX_SIZE_BYTES) {
        toast({
          title: "Rekaman Gagal",
          description: "Rekaman tidak boleh lebih dari 5MB.",
          status: "error",
          duration: 10000,
          isClosable: true,
        });
        setRecordingDuration(0);
        return;
      }
      
      const base64Audio = await blobToBase64(blob);
      setAudioBlob(blob);
      onRecordingComplete(base64Audio);
    } catch (error) {
      console.error('Error converting audio to base64:', error);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        handleRecordingComplete(audioBlob);
        audioChunksRef.current = [];
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setRecordingDuration(0);

      // Start timer to track recording duration
      timerRef.current = setInterval(() => {
        setRecordingDuration((prevDuration) => {
          const newDuration = prevDuration + 1;
          if (newDuration >= maxDuration) {
            mediaRecorderRef.current.stop();
            stopRecording();
          }
          return newDuration;
        });
      }, 1000);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      clearInterval(timerRef.current);
  };

  const playRecording = () => {
    if (audioBlob) {
      const audio = new Audio(URL.createObjectURL(audioBlob));
      audio.play();
    }
  };

  return (
    <Box>
      <Text mb={2}>Durasi rekaman: {recordingDuration} detik</Text>
      <HStack spacing={4}>
        <Button
          onClick={isRecording ? stopRecording : startRecording}
          colorScheme={isRecording ? "red" : "blue"}
          rightIcon={isRecording ? <FaMicrophoneSlash /> : <FaMicrophone />}
        >
          {isRecording ? "Stop rekaman" : "Rekam Suara"} 
        </Button>
        {audioBlob && (
          <Button onClick={playRecording} colorScheme="green">
            Putar Ulang Suara
          </Button>
        )}
      </HStack>
    </Box>
  );
};



const VoiceNoteSelector = ({ overlay, values, setFieldError, currency, setFieldValue }) => {
  const { mediaType } = values;
  const {
    min_media: minimumMedia,
    mediashare_rate: mediashareRate,
    mediashare_max_duration: mediashareMaxDuration,
  } = overlay;

  const getPlayDuration = (
    mediashareRate,
    mediashareMaxDuration,
    amount,
  ) => {
    return Math.min(Math.floor(amount / mediashareRate), mediashareMaxDuration);
  };

  const enabled = Number(values.amount) >= minimumMedia;
  if (!enabled) {
    return (
      <FormControl width="100%" mb={3}>
        <FormHelperText>
          <Trans>
            Dukungan minimum untuk menyertakan Voice Note adalah{" "}
            {formatMoney(minimumMedia, { currency })}
          </Trans>
        </FormHelperText>
      </FormControl>
    );
  }

  return (
    <>
      {mediaType === "voice_note" && (
        <VoiceNoteForm
          mediashareRate={mediashareRate}
          mediashareMaxDuration={mediashareMaxDuration}
          donationAmount={values.amount}
          onRecordingComplete={(audioBlob) => {
            // Handle the recorded audio blob
            setFieldValue("voice_note", audioBlob);
          }}
        />
      )}
      <Stack spacing="8">
        <FormControl width="100%">
          <FormHelperText>
            <Trans>
              {formatMoney(mediashareRate, { currency })} / detik <br /> Durasi
              maksimum rekaman 60 detik <br />
              Rekaman akan diputar mulai detik 0 hingga{" "}
            </Trans>
            {getPlayDuration(
              mediashareRate,
              mediashareMaxDuration,
              values.amount
            )}
          </FormHelperText>
        </FormControl>
      </Stack>
    </>
  );
};

export { VoiceNoteSelector };

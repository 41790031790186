import React from "react";
import dynamic from "next/dynamic";

import { FormHelperText, Flex, Image, FormControl } from "@chakra-ui/react";
import { formatMoney } from "../../../utils";
import { Field } from "formik";
import { Trans } from "@lingui/macro";

const GiphySelector = dynamic(
  () => {
    return import("../../../components/Giphy/GiphySelector").then(
      (mod) => mod.GiphySelector
    );
  },
  { ssr: false }
);

const GifSelector = ({ overlay, currency }) => {
  return (
    <Field name="giphy">
      {({ field, form }) => {
        const { setFieldValue, values } = form;
        const { mediaType } = values;
        const { min_media: minimumMedia } = overlay;
        const enabled = Number(values.amount) >= minimumMedia;

        if (!enabled) {
          return (
            <FormControl width="100%" mb={3}>
              <FormHelperText>
                <Trans>
                  Minimum untuk menyertakan gif adalah{" "}
                  {formatMoney(overlay.min_media, { currency })}
                </Trans>
              </FormHelperText>
            </FormControl>
          );
        }

        return (
          <FormControl>
            {field.value && mediaType === "gif" ? (
              <Flex w="100%" h="200px" justify="center" ratio="1" mb="3">
                <Image
                  alignSelf="center"
                  src={field.value.images.fixed_height_downsampled.url}
                  objectFit="contain"
                />
              </Flex>
            ) : null}
            <Flex justify="flex-end">
              <GiphySelector
                disabled={overlay.min_media > values.amount}
                onSelectedGif={(gif) => {
                  setFieldValue("giphy", gif);
                }}
              />
            </Flex>
          </FormControl>
        );
      }}
    </Field>
  );
};

export { GifSelector };
